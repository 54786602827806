<template>
    <v-app>
        <div class="d-block" >
            <div class="login-c left mt-15">
                <v-row justify="center" class="login-form">
                    <v-col cols="6">
                        <v-alert
                            color="red"
                            type="error"
                            v-if="error"
                        >{{error}}
                        <div @click="error = null" class="alartclose">
                            <v-icon>fas fa-times-circle</v-icon>
                        </div>
                        </v-alert>
                        <v-alert
                            color="green"
                            type="error"
                            v-if="msg"
                        >{{msg}}
                        <div @click="msg = null" class="alartclose">
                            <v-icon>fas fa-times-circle</v-icon>
                        </div>
                        </v-alert>
                    </v-col>
                </v-row>
                <v-row justify="center" class="login-form">
                    <v-col cols="12" sm="10" md="8" lg="6" >
                        <h1>ADK Payment Request Portal</h1>
                    </v-col>
                </v-row>
                <v-row justify="center" class="login-form">
                    <v-col cols="12" sm="10" md="8" lg="6">
                        <div class="d-flex items-center mb-4 align-center">
                            <h2>Reset Password</h2>
                        </div>
                        <div v-if="success">
                            <v-alert
                                color="green"
                                type="success"
                                >
                                Password updated
                            </v-alert>
                            <v-btn
                            color=""
                            light
                            elevation="2"
                            @click="$router.push('/admin')"
                            class="mt-2"
                            >
                                Login
                            </v-btn>
                        </div>
                        <v-form ref="form" v-model="valid" lazy-validation @submit.native="submit" v-else-if="!validatingToken && !invalidToken" >
                            <v-text-field
                            v-model="password"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPass ? 'text' : 'password'"
                            :rules="passwordRules"
                            label="Password"
                            @click:append="showPass = !showPass"
                            required
                            class="mb-2"
                            :disabled="loading"
                        ></v-text-field>
                        <div style="margin-bottom:5px">
                                <p style="margin-bottom:5px">Password policy</p>
                                <ul>
                                    <li :class="r1?'':'text-red'">At least one upper case letter</li>
                                    <li :class="r2?'':'text-red'">At least one lower case letter</li>
                                    <li :class="r3?'':'text-red'">At least one digit</li>
                                    <li :class="r4?'':'text-red'">At least one special character</li>
                                    <li :class="r5?'':'text-red'">Minimum eight in length</li>
                                </ul>
                            </div>
                        <v-btn
                        color="blue accent-4"
                        dark
                        elevation="2"
                        :disabled="loading"
                        @click="submit"
                        class="mt-2"
                        >
                            Submit
                        </v-btn>
                        <div v-if="loading">
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </div>
                        </v-form>
                        <div v-else-if="validatingToken">
                            <v-progress-circular
                            indeterminate
                            color="primary"
                            ></v-progress-circular>
                        </div>
                        <div v-else-if="invalidToken">
                            <v-alert
                            color="red"
                            type="error"
                            >
                            Invalid token
                            </v-alert>
                            <v-btn
                            color=""
                            light
                            elevation="2"
                            @click="$router.push('/admin')"
                            class="mt-2"
                            >
                                Login
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="login-c right logocont">
                <div class="logofloat">
                    <div class="logor">
                        <svg class="cloginr bottom-right" viewBox="0 0 220 192" width="220" height="192" fill="none"><defs class="ng-tns-c94-0"><pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse" class="ng-tns-c94-0"><rect x="0" y="0" width="4" height="4" fill="currentColor" class="ng-tns-c94-0"></rect></pattern></defs><rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" class="ng-tns-c94-0"></rect></svg>
                        <img :src="adklogo" alt="ADK" class="logo text-center" >
                        <svg class="cloginr logotr" viewBox="0 0 960 540" width="100%" height="100%" preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="currentColor" stroke-width="100" class="text-gray-700 opacity-25 ng-tns-c94-0"><circle r="234" cx="196" cy="23" class="ng-tns-c94-0"></circle><circle r="234" cx="790" cy="491" class="ng-tns-c94-0"></circle></g></svg>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import logo from "../assets/logowhite.png"
export default {
    watch:{
        password:function(){
            this.r1 = /^(?=.*?[A-Z])/.test(this.password)
            this.r2 = /^(?=.*?[a-z])/.test(this.password)
            this.r3 = /^(?=.*?[0-9])/.test(this.password)
            this.r4 = /^(?=.*?[#?!@$%^&*-])/.test(this.password)
            this.r5 = /.{8,}$/.test(this.password)
        }
    },
    data() {
        return{
            r1:false,
            r2:false,
            r3:false,
            r4:false,
            r5:false,
            success:false,
            validatingToken:true,
            invalidToken:true,
            error:"",
            msg:"",
            adklogo:logo,
            token:"",
            password:"",
            showPass:false,
            valid:true,
            loading:false,
            passwordRules: [
                v => /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(v) || 'The password does not meet the password policy',
            ],
        }
    },
    mounted(){
        if(!this.$route.query.token){
            this.validatingToken = false;
            this.invalidToken = true;
        }
        else{
            this.$http.get(`${process.env.VUE_APP_API_ROOT}/auth/verifyToken?token=${this.$route.query.token}`)
                .then(this.handleValidToken)
                .catch(this.handleInvalid)
        }
    },
    methods:{
        submit(){
            this.loading = true;
            if(this.$refs.form.validate()){
                this.$http.post(`${process.env.VUE_APP_API_ROOT}/auth/resetPassword?token=${this.$route.query.token}`,{password:this.password})
                    .then(this.handleres)
                    .catch(this.handleerr)
            }
            else{
                this.loading = false;
                this.error = "Please enter a valid password"
            }
        },
        handleres(){
            this.loading = false;
            this.error = false;
            this.success = true;
        },
        handleerr(e){
            this.loading = false;
            this.error = e.response.data.message;
        },
        handleValidToken(){
            this.validatingToken = false;
            this.invalidToken = false;
        },
        handleInvalid(){
            this.validatingToken = false;
            this.invalidToken = true;
        }
    }
}
</script>


<style lang="scss">
    .text-red{
        color: red;
    }
    .login-c{
        width: 50%;
    }
    .left{
        float: left;
    }
    .right{
        float:right;
    }
    .alartclose{
        background-color: transparent !important;
        border:transparent !important;
        box-shadow: none !important;
        float: right;
        min-width: unset !important;
        height: unset !important;
        cursor: pointer;
    }
    .logocont{
        position: relative;
        height: 100vh;
        .top-left{
            position: absolute;
            top:0;
            right: 0;
        }
        .bottom-right{
            position: absolute;
            bottom:-55px;
            left: 0;
        }
        .cloginr{
            color: #2962FF  ;
        }
        .logofloat{
            background: #1754fc ;
            position: fixed;
            width:50%;
            height: 100%;
            .logor{
                position: relative;
                width: 100%;
                height: 100%;
                .logo{
                    position: absolute;
                    top: 50%;
                    left:50%;
                    transform: translate(-50%,-50%);
                }
            }
        }
    }
    @media only screen and (max-width: 600px) {
        .login-c.right.logocont{
            display: none;
        }
        .login-c.left{
            width:100%;
            padding:0 30px;
        }
        .row.login-form > .col.col-6{
            flex: 1 1 100%;
            max-width: 100%;
        }
    }
</style>